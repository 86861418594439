.admin__program__details__section {
  /* padding: 20px; */
  /* margin-right: 10px; */
  /* border-color: #919191;
  border-style: solid;
  border-width: 0 1px 1px 1px; */
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-sizing: border-box;
  /* height: calc(100vh - 160px); */
}

.admin__program__details__section h6 {
  color: #000;
}

.program__details__subsection,
.admin__program__manage {
  background-color: #fff;
  padding: 20px;
  /* padding-bottom: 0px !important; */
  border-radius: 12px;
  /* border: 1px solid #707070; */
}

.admin__program__manage .nav-pills .nav-link.active {
  background: transparent;
  color: #f47e53 !important;
  border-bottom: 2px solid#f47e53;
  font-weight: 600;
  border-radius: 0% !important;
}

.admin__program__manage .nav-link {
  color: #000;
  font-weight: 600;
}

.admin__program__manage .nav-link:hover {
  /* color: #f47e53 !important; */
}

.program__details__subsection .program__icon {
  height: 100px;
  min-width: 100px;
  border-radius: 8px;
}

.edit__program__btn {
  min-width: 160px;
  border: none;
  padding: 10px;
  border-radius: 6px;
  font-weight: 500;
  background: #f47e53;
  color: #fafafa;
}

.program_scroll {
  flex: 1;
}

.program__name h4,
.program__name h6 {
  color: #292929;
}

.program__profile__part {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .edit__program__btn {
    padding: 10px 9px;
  }
}

@media (max-width: 425px) {
  .program__details__subsection .program__icon {
    height: 70px;
    width: 85px;
  }

  .program__name h4 {
    font-size: 16px;
  }

  .program__name h6 {
    font-size: 13px;
  }

  .progam__listing__section {
    display: block;
  }

  .admin__program__details__section {
    padding: 30px 15px;
  }

  .edit__program__btn {
    margin-top: 10px;
  }
}

.SwitchContainer {
  display: flex;
  background-color: rgb(209, 209, 209);
  align-items: center;
  border-radius: 35px;
  width: max-content;
  height: max-content;
  border: solid;
  border-color: rgb(209, 209, 209);
}

.ToggleItem {
  height: 24px;
  width: 40px;
  display: flex;
  border-radius: 35px;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  cursor: pointer;
}

.Text {
  color: white;
  font-size: 14px;
}
