// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

:root {
  --background-color: #f5f5f5 !important;
  --background-color-card: #fff;
  --border-color: #d5d5d5;
  --header-font: #6d6d6d;
  --report-cmp: #626263;
  --select-background: #fff;
  --select-border: #cfcbcb;
  --card-color: #fff;
  --text-color: #000;
  --add-program-input: #e5e7e8;
  --invert-color: 0;
  --board-text-color: #1e1f21;
  --drop-down-color: #fff;
  --card-box-shadow: #959db940;
  --add-btn-hover: #000;
  --modal-color: #fff;
  --task-hover: #091e4214;
  --card-border-hover: rgba(0, 0, 0, 0.363);
  --card-border: gainsboro;
  --dashed-border: #000;
  --add-button-color: #6d6e6f;
  --user-back-color: #fff;
  --button-text-color: #6d6e6f;
  --button-hover-color: #f9f8f8;
  --icon-color: #afabac;
  --user-icon-color: #292d32;
  --cmt-time-color: #6d6e6f;
  --dropdown-background-color: #fff;
  --sidebar-background: #f9f8f8;
  --card-back-color: #fff;
  --select-back-color: #f1f1f1;
  --cmt-btn-back: #000;
  --login-girl-container: #eeedf3;
  --input-background: #fff;
  --modal-back-color: #fff;
  --sidebar-border: #ccc;
  --profile-modal-back: #f6f6f6;
  --upload-btn: #ececec;
  --form-border: #dcdcdc;
  --scrollbar-back: #f2f2f2;
  --scrollbar-thumb: #ccc;
  --scrollbar-thumb-hover: #c3c3c3;
}

[data-theme="dark"] {
  --background-color: #1c1c1c;
  --background-color-card: #1c1c1c;
  --border-color: #1b1b1b;
  --header-font: #fff;
  --report-cmp: #c8c8c8;
  --select-background: #1c1c1c;
  --select-border: none;
  --card-color: #212121;
  --card-box-shadow: #2a2a2a;
  --drop-down-color: #383838;
  --text-color: #fff;
  --add-program-input: #e5e5e529;
  --invert-color: 1;
  --board-text-color: #fff;
  --add-btn-hover: #fff;
  --modal-color: #2d2d2d;
  --task-hover: rgba(65, 103, 241, 0.2);
  --card-border-hover: rgba(255, 255, 255, 0.363);
  --card-border: transparent;
  --dashed-border: #fff;
  --add-button-color: #fff;
  --user-back-color: #000;
  --button-text-color: #fff;
  --button-hover-color: transparent;
  --icon-color: #afabac;
  --user-icon-color: #fff;
  --cmt-time-color: #bababa;
  --dropdown-background-color: #000;
  --sidebar-background: #111111;
  --card-back-color: #1a1a1a;
  --select-back-color: #1a1a1a;
  --cmt-btn-back: #4767f1;
  --login-girl-container: rgba(87, 80, 137, 0.1);
  --input-background: transparent;
  --modal-back-color: #111111;
  --sidebar-border: #cccccc45;
  --profile-modal-back: #1a1a1a;
  --upload-btn: rgb(71, 103, 241, 0.2);
  --form-border: #515151;
  --scrollbar-back: #424242;
  --scrollbar-thumb: #686868;
  --scrollbar-thumb-hover: #808080;
}

body {
  margin: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
  background-color: var(--background-color);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}