.admin__main__dash {
  background-color: #fff;
  border-color: #fff;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-radius: 12px;
}

.admin__header__dashboard {
  /* padding-bottom: 20px; */
  /* padding-left: 30px; */
  /* padding-right: 30px; */
  color: var(--text-color);
}


.admin__received__report__first h6,
.admin__dashboard__small {
  color: #000 !important;
}

.admin__dash__title {
  font-size: 24px;
  /* line-height: 36px; */
  /* padding-bottom: 14px; */
  color: #292929;
  /* border-bottom: 2px solid #d3d3d3; */
}

.admin__received__report__first {
  position: relative;
  font-size: 18px;
  color: #000;
  background: #f5f5f5;
  /* box-shadow: 0px 8px 24px var(--card-box-shadow); */
  border-radius: 8px;
  height: 124px;
}

.admin__image__roundOne {
  width: 53px;
  height: 53px;
  border-radius: 50%;
  border: 3px solid rgba(251, 145, 85, 0.5);
}

.admin__imageOne__style {
  height: 18px;
  width: 18px;
}

.admin__image__roundTwo {
  width: 53px;
  height: 53px;
  border-radius: 50%;
  border: 3px solid rgba(99, 204, 250, 0.5);
}

.admin__image__roundThree {
  width: 53px;
  height: 53px;
  border-radius: 50%;
  border: 3px solid rgba(241, 63, 45, 0.5);
}

.admin__image__roundFour {
  width: 53px;
  height: 53px;
  border-radius: 50%;
  border: 3px solid rgba(229, 163, 84, 0.5);
}

@media (max-width: 1024px) {
  .admin__main__dash {
    margin-left: 10px;
  }
}
