.no__transcation {
  max-width: 600px;
  margin: 0 auto;
}

.no__transcation h5 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.candidate-leads-section {
  /* border-color: #919191;
      border-style: solid;
      border-width: 0 1px 1px 1px; */
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.candidate-leads-modal .modal-content {
  max-width: 500px !important;
  min-width: 300px !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
  overflow-y: unset !important;
  max-height: 584px !important;
}
.candidate-leads-modal .modal-body {
  padding: 13px 13px 0px 13px;
  overflow-y: auto;
}

.candidate-leads-section {
  padding: 1.5rem;
}
.candidate-leads-modal .modal-heading {
  /* margin-bottom: 8px; */
  font-size: 16px;
}

.candidate-leads-listing {
  background-color: #fff;
  border-radius: 8px;
  margin-top: 15px;
}

.candidate-leads-listing p,
.candidate-leads-listing h6,
.candidate-leads-listing hr {
  color: #fff;
}

.add__candidate-leads__form .candidate-leads-name,
.add__candidate-leads__form .add__candidate-leads__form {
  height: 40px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--select-border);
  padding: 6px;
  background-color: var(--select-background);
  color: var(--text-color);
}
.candidate-leads-subsection .add-candidate-leads {
  border: none;
  color: #fff;
  border-radius: 6px;
  padding: 10px 15px !important;
  font-weight: 500;
  background-color: #f47e53;
}
.candidate-leads-save-btn {
  background-color: #f47e53;
  color: #000;
  cursor: pointer;
  outline: none;
  width: 100%;
  height: 47px;
  line-height: 47px;
  border-radius: 5px;
  padding: 0;
  border: none;
  color: #fff;
  font-weight: 500;
}

.candidate-leads-listing p {
  font-weight: 600;
}

.candidate-leads-data h6 {
  min-width: 200px;
}

.add-candidate-leads-close-section {
  /* margin-top: 15px; */
  /* display: flex;
  align-items: center; */
  /* justify-content: flex-end; */
}

.candidate-leads-list-table {
  max-width: 100%;
}

.candidate-leads-list-table td,
.candidate-leads-list-table th {
  color: #000;
}

.candidate-leads-list-table th {
  font-weight: 500;
  font-size: 16px !important;
  line-height: 24px !important;
}

.candidate-leads-list-table tbody tr {
  border-bottom: 1px solid #d3d3d3 !important;
}

.candidate-leads-list-table thead tr th {
  border-bottom: 0 !important;
}

.candidate-option .select__menu {
  top: unset;
  bottom: 100%;
}

@media (max-width: 1024px) {
  .candidate-leads-section {
    margin-left: 10px;
  }
  .candidate-leads-data {
    overflow-x: auto;
  }
}

@media (max-width: 576px) {
  .candidate-leads-list-table,
  .client__header {
    width: 576px;
  }
  .candidate-leads-data {
    overflow-x: auto;
  }
}
