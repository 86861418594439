.myTeam_section,
.myTeam_listing {
  padding: 10px;
}
.modal_heading {
  margin-bottom: 8px;
}

.myTeam_listing {
  background-color: #fff;
  border-radius: 8px;
  margin-top: 15px;
}

.myTeam_listing p,
.myTeam_listing h6,
.myTeam_listing hr {
  color: #fff;
}

.myTeam_data h6 {
  min-width: 200px;
}

.myTeam_list_table {
  max-width: 100%;
}

.myTeam_list_table td,
.myTeam_list_table th {
  color: #000;
}

.myTeam_list_table th {
  font-weight: 500;
  font-size: 16px !important ;
  line-height: 24px !important;
}

.myTeam_list_table tbody tr {
  border-bottom: 1px solid #d3d3d3 !important;
}
.myTeam_list_table thead tr th {
  border-bottom: 0 !important;
}

@media (max-width: 1024px) {
  .myTeam_section {
    margin-left: 10px;
  }
  .add_assets {
    padding: 8px 10px !important;
  }
}

@media (max-width: 804px) {
  .myTeam_list_table,
    .myTeam_data {
    overflow-x: auto;
  }
}
