.no__transcation {
  max-width: 600px;
  margin: 0 auto;
}

.no__transcation h5 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.assets-item-select .select__menu-list {
  height: 200px !important;
  overflow-y: auto !important;
}
.assets_section {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.assets_modal .modal-content {
  max-width: 500px !important;
  min-width: 300px !important;
  max-height: 650px !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
  /* overflow-y: unset !important; */
}

.assets_modal .modal-body {
  padding: unset !important;
}

/* .assets_modal .select__menu {
  position: static;
} */

/* .view-modal .modal-content {
  max-width: 1000px !important;
  min-width: 300px !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
  overflow-y: unset !important;
} */
.modal-body-assets::-webkit-scrollbar {
  display: none;
}

.assets_section,
.assets__listing {
  padding: 10px;
}
.modal_heading {
  margin-bottom: 8px;
}

.assets__listing {
  background-color: #fff;
  border-radius: 8px;
  margin-top: 15px;
}

.assets__listing p,
.assets__listing h6,
.assets__listing hr {
  color: #fff;
}

.add_assets_form .assets_name {
  height: 40px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--select-border);
  padding: 6px;
  background-color: var(--select-background);
  color: var(--text-color);
}

.assets__textarea {
  width: 100%;
  min-height: 100px;
  border-radius: 8px;
  border: 1px solid var(--select-border);
  padding: 6px;
  background-color: var(--select-background);
  color: var(--text-color);
}

.assets_name:disabled {
  background-color: #e8e8e8;
  cursor: not-allowed;
}

.assets_subsection .add_assets {
  border: none;
  color: #fff;
  border-radius: 6px;
  padding: 10px 15px;
  background-color: #f47e53;
}


.report_header_border {
  border-bottom: 1px solid #d3d3d3 !important;
}
.assets_save_btn {
  background-color: #f47e53;
  color: #000;
  cursor: pointer;
  outline: none;
  width: 100%;
  height: 50px;
  line-height: 47px;
  border-radius: 12px;
  padding: 0;
  border: none;
  color: #fff;
  font-weight: 500;
}

.total__amount {
  color: #37aa1b;
  font-size: 18px;
}

.assets__listing p {
  font-weight: 600;
}

.project__header {
  background: #fff;
  border-radius: 4px;
}

.assets__data h6 {
  min-width: 200px;
}

.assets__list__table {
  max-width: 100%;
}

.assets__list__table td,
.assets__list__table th {
  color: #000;
}

.assets__list__table th {
  font-weight: 500;
  font-size: 16px !important ;
  line-height: 24px !important;
}

.assets__list__table tbody tr {
  border-bottom: 1px solid #d3d3d3 !important;
}
.assets__list__table thead tr th {
  border-bottom: 0 !important;
}

.select-zindex .select__menu {
  z-index: 9999; /* Set the z-index to a high value */
}

@media (max-width: 1024px) {
  .assets_section {
    margin-left: 10px;
  }
  .add_assets {
    padding: 8px 10px !important;
  }
}

@media (max-width: 804px) {
  .assets__list__table,
  /* .project__header {
    width: 804px;
  } */
  .assets__data {
    overflow-x: auto;
  }
}

/* .close__section {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
} */

/* .projectLead__save__btn {
  background-color: #f47e53;
  color: #000;
  cursor: pointer;
  outline: none;
  width: 230px;
  height: 47px;
  line-height: 47px;
  border-radius: 5px;
  padding: 0;
  border: none;
  color: #fff;
  font-weight: 500;
}

.Project_lead_description {
  /* height: 150px !important; */
/* height: 100px !important;
  border-radius: 8px !important;
  border: 1px solid var(--select-border);
  padding: 6px;
  background-color: var(--select-background);
  color: var(--text-color);
  width: 100%;
} */

/* .Join_task__save__btn {
  background-color: #ffffff;
  color: #6f767e;
  cursor: pointer;
  outline: none;
  width: 116px;
  height: 44px;
  border-radius: 5px;
  padding: 0;
  border: none;
  font-weight: 500;
  border: 1px solid #ecedf0;
} */

/* Hide arrows in input type number for Chrome, Safari, Edge, Opera */
input.assets_name[type="number"]::-webkit-outer-spin-button,
input.assets_name[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide arrows in input type number for Firefox */
input.assets_name[type="number"] {
  -moz-appearance: textfield;
}

.add_assets_form .assets_note {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--select-border);
  padding: 6px;
  background-color: var(--select-background);
  color: var(--text-color);
}
