.hunter_audit_dashboard .dashboard_audit_sup_part {
  background-color: #f5f5f5;
  color: #000;
  border-color: #d3d3d3;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-radius: 12px;
}

.hunter_audit_scope_section {
  padding: 20px 20px 20px 20px;
}

.hunter_audit_scope_section .scope_inner_div {
  background-color: #fff;
  padding: 20px 20px 20px 32px;
  border-radius: 15px;
}

/* ////sbgfsahkj */

.dashboard__sup__part {
  background-color: #141414;
  color: #fff;
  /* margin-right: 10px; */
  border-color: #919191;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.reports__images__style {
  width: 45px;
}

.report__font {
  color: #000;
  font-size: 16px;
}

.main__report__card {
  background-color: transparent;
  position: relative;
}

.reports__card {
  background-color: #fff;
  border-top: 3px solid #f47e53;
  /* border-top-right-radius: 10px;
  border-top-left-radius: 10px; */
  transform: translate(0px, -7px);
  border-radius: 10px;
}

.report__count__font {
  font-size: 16px;
  font-weight: 500;
}

.chart__style {
  /* background-color: #d3d3d3; */
  border-radius: 20px;
  padding: 10px;
}

.chart__images {
  height: 25px;
}

.padding__chart__details {
  padding-right: 200px;
  margin-top: 16px;
}

.chart__legend {
  gap: 1em;
}

.bottom__rect {
  /* height: px;
    width: 96px;
    max-width: 96px; */
  padding: 20px;
}

.first {
  background: transparent linear-gradient(180deg, #7b1abc 0%, #3b3b3e 60%) 0% 0%
    no-repeat padding-box;
  border-radius: 17px;
  opacity: 0.77;
}

.second {
  background: transparent linear-gradient(180deg, #ddd71f 0%, #3b3b3e 60%) 0% 0%
    no-repeat padding-box;
  border-radius: 17px;
  opacity: 0.77;
}

.third {
  background: transparent linear-gradient(180deg, #f9ac11 0%, #3b3b3e 60%) 0% 0%
    no-repeat padding-box;
  border-radius: 17px;
  opacity: 0.77;
}

.fourth {
  background: transparent linear-gradient(180deg, #f81d1d 0%, #3b3b3e 60%) 0% 0%
    no-repeat padding-box;
  border-radius: 17px;
  opacity: 0.77;
}

.main__reward__section {
  background-color: #000000;
  border-radius: 15px;
}

.smallest__text {
  color: #2cca75;
  font-weight: 500;
}

.dollar__style {
  padding: 0;
  margin: 0;
  font-size: 16px;
}

.reward__title {
  color: #ffffff;
  font-size: 16px;
  height: 60px;
}

.bottom__border__set {
  border-radius: 7px;
  text-align: center;
  border-bottom: 6px solid transparent;
  border-image: linear-gradient(
      180deg,
      rgba(17, 255, 127, 0.59) 0%,
      rgba(20, 14, 177, 0.59) 100%
    )
    20%;
}

.bottom-line {
  height: 8px;
  width: 70%;
  background: transparent linear-gradient(180deg, #11ff7f 0%, #140eb1 80%) 0% 0%
    no-repeat padding-box;
  border-radius: 7px;
  opacity: 0.59;
}

.table__sup {
  /* backgroundCol: linear-gradient(
      180deg,
      rgba(166, 166, 172, 0.5) 0%,
      rgba(65, 65, 68, 1) 100%
    ); */
  /* border-radius: 15px; */
}

.bug__bounty__table {
  background-color: #fff;
  /* border-radius: 10px; */
}

.bug__bounty__table:hover {
  /* background-color: #3b3b3b; */
}

.table__part {
  height: 170px;
}

.main__part {
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-bottom: 200px;
}

.cmp__dashboard_back_circle1 {
  position: absolute;
  top: -3%;
  left: 40%;
  z-index: -1;
}

.horizontal__line {
  height: 10px;
  margin-top: 75px;
  /* background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(125, 120, 120, 1) 70%
    ); */
}

.chart__section {
  padding: 25px 50px 20px 0px;
}

.image__part__height {
  margin: 7px 10px 7px 0;
  height: 45px;
  width: 45px;
  border-radius: 50%;
}

canvas {
  width: 250px !important;
  height: 250px !important;
}

.charjsData {
  margin: 0 70px 0 85px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 320px;
}

.chartLabels {
  display: flex;
  align-items: center;
}

.chartLabels {
  margin-bottom: 5px;
}

.chartLabels p {
  margin-bottom: 0;
  margin-left: 10px;
}

.legend__img {
  height: 40px;
  width: 40px;
}

.rect__data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
  font-size: 16px;
}

.rec__report__dash {
  padding: 24px 0 24px 48px;
}

.rec__report__main__right {
  padding-right: 20px;
  padding-left: 20px;
}

.rec__report__main__left {
  margin-block: auto !important;
}

.rec__report__main {
  padding-left: 0;
  padding-right: 0;
}

@media (max-width: 1650px) {
  .charjsData {
    margin: 0;
    justify-content: space-evenly;
  }
}

@media (max-width: 1440px) {
  .padding__chart__details {
    padding-right: 30px;
  }

  .charjsData {
    margin: 0px;
    justify-content: space-around;
  }

  .chart__section {
    padding: 25px 10px 25px 0px;
  }

  .bottom__rect {
    width: 60px;
  }

  .dollar__style {
    font-size: 25px;
    margin-top: 15px;
    margin-bottom: 5px;
  }
}

@media (max-width: 1200px) {
  .dollar__style {
    font-size: 20px;
  }
  canvas {
    width: 230px !important;
    height: 230px !important;
  }
}

@media (max-width: 1024px) {
  .padding__chart__details {
    padding-right: 10px;
  }
  .main__part {
    margin-left: 10px;
  }
  canvas {
    width: 200px !important;
    height: 200px !important;
  }
  .dollar__style {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  .padding__chart__details {
    padding-right: 10px;
  }

  .image__part__height {
    width: 45px;
  }
}

@media (max-width: 576px) {
  .horizontal__line {
    display: none;
  }

  .chart__section {
    padding: 25px 30px 20px 0px;
  }
}
