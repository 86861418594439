.report-section {
  margin-right: 10px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.report-subsection {
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
}
