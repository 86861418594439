.Updates_name {
  max-width: 200px !important;
  min-width: 200px !important;
}

.Updates_name .__menu-list {
  max-height: 200px !important;
}

.update_input_field {
  border-radius: 8px !important;
  border: 1px solid var(--select-border);
  padding: 6px;
  background-color: var(--select-background);
  color: var(--text-color);
  width: 100%;
}

.update_input_time{
  height: 40px !important;
  border-radius: 8px !important;
  border: 1px solid var(--select-border);
  padding: 6px;
  background-color: var(--select-background);
  color: var(--text-color);
  width: 100%;
}