@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&family=PT+Sans+Caption&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  font-family: Poppins !important;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  /* Remove the ring shadow and set the border color */
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: none; /* Set the ring shadow to none */
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
  /* border-color: transparent; */
}

.App {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* background-color: #0a0a0a; */
  /* background-color: var(--background-color); */
  transition: all 350ms;
}

/* ul {
  list-style-type: disc;
  margin-left: 10px;
} */

ol {
  list-style: auto;
}

ol li {
  margin-left: 20px;
}

.Appother {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: all 350ms;
  /* background-color: #000; */
}

::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #f2f2f2; */
  background: var(--scrollbar-back);
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #ccc; */
  background: var(--scrollbar-thumb);
  border-radius: 20px 20px 20px 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #c3c3c3; */
  background: var(--scrollbar-thumb-hover);
}

.main__components {
  height: 100vh;
  overflow: auto;
  max-width: calc(100vw - 260px);
}

.main__components,
::-webkit-scrollbar {
  /* display: none; */
}

.scroll-tab ::-webkit-scrollbar-thumb {
  /* background: #ccc; */
  height: 1px;
  width: 10px;
}

.main__section {
  display: flex;
  /* background-color: #000; */
  /* margin-top: 15px; */
}

.main__section__company {
  display: flex;
  /* margin-top: 15px; */
}

.cards {
  border: none;
  border-radius: 8px;
  background-color: var(--modal-color);
  color: var(--text-color);
  width: 100%;
  /* padding: 30px; */
  box-sizing: border-box;
  /* box-shadow: 0 0 4px 4px #f1f2f3; */
}

.common-btn {
  width: 100%;
  height: 32px;
  border: none;
  border-radius: 23px;
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
  color: #fff;
  background-color: #93a600;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@media (max-width: 1024px) {
  .main__components {
    max-width: 100vw;
  }
}

.change__style .ql-toolbar.ql-snow + .ql-container.ql-snow {
  background-color: #f4f4f4;
  border-radius: 0px 0px 12px 12px;
}

.change__style .ql-editor {
  @media (max-width: 1535px) {
    min-height: 100px !important;
  }
}

.change_admin_style .ql-toolbar .ql-stroke {
  fill: none;
  stroke: #fff !important;
}

.change_admin_style .ql-toolbar .ql-formats {
  fill: #fff;
  stroke: none !important;
}

.change_admin_style .ql-toolbar .ql-picker {
  color: #fff !important;
}

.profile_editor .ql-editor {
  height: 140px !important;
}

.basic-single,
.select__control,
.react-select {
  border-radius: 8px !important;
}

input {
  cursor: text;
}

input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.select__indicator {
  cursor: pointer;
}
