.daily_updates_section {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 10px;
}

.daily_updates_subsection {
  border: none;
  color: #fff;
  border-radius: 6px;
  padding: 15px;
  font-weight: 500;
  background-color: #f47e53;
}

.daily_updates_date {
  line-height: 20px !important;
  font-size: 18px !important;
}
.daily_updates_project_name h6 {
  line-height: 18px !important;
  font-size: 16px !important;
}
.daily_updates_project_hours h6 {
  font-size: 16px !important;
  line-height: 32px !important;
  color: #6f767e !important;
}
.daily_updates_hours {
  border: none;
  color: #fff;
  border-radius: 6px;
  padding: 10px 15px;
  font-weight: 500;
  background-color: #f47e53;
  margin-bottom: 25px;
  /* margin-left: 0rem !important; */
}
