

.login__section {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  background: #0a0a0a;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.back_circle1 {
  position: absolute;
  bottom: 37%;
  left: -69%;
  z-index: -1;
}

.back_circle2 {
  position: absolute;
  top: -76%;
  left: -38%;
  z-index: -1;
}

.back_circle3 {
  position: absolute;
  top: -47%;
  left: 44%;
  z-index: -1;
}

.back_circle4 {
  position: absolute;
  bottom: 40%;
  left: 39%;
  transform: rotate(307deg);
  z-index: -1;
}

.back_circle5 {
  position: absolute;
  top: 16%;
  left: 67%;
  transform: rotate(20deg);
  z-index: -1;
}

.login__container {
  min-width: 440px;
  padding: 32px 32px 32px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.login__subsection {
  background-color: #fff;
  border-radius: 12px;
  padding: 15px;
  z-index: 1;
}

.login__button button {
  background-color: #5b5bfb;
  color: #fff;
  border: none;
  font-size: 16px;
  border-radius: 25px !important;
  cursor: pointer;
  display: flex;
  height: 40px;
  width: 100%;
  padding: 6px 12px;
  position: relative;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
}

.email__section input,
.pwd__section input {
  width: 100%;
  height: 36px;
  padding: 6px 12px;
  box-sizing: border-box;
  border: 1px solid #afabac;
  font-size: 14px;
  font-weight: 400;
  margin-top: 7px;
}

.email__section input:focus,
.pwd__section input:focus {
  outline: none;
}

.pwd__section,
.email__section {
  margin-top: 25px;
}

.lock__icon,
.email__icon {
  position: absolute;
  left: 10px;
  top: 15px;
}

.login__button {
  margin-top: 35px;
}

.login__button button:hover {
  background-color: #575089;
}

.not__account a {
  text-decoration: none;
  color: #5a5af8;
  font-weight: 600;
}

.not__account {
  font-size: 16px;
  margin-top: 20px;
  text-align: center;
}

.login__section .right__container {
  height: 525px;
  width: 440px;
}

.login__section .right__container img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.kanban__logo,
.login__title {
  text-align: center;
  margin-bottom: 35px;
}

.kanban__logo {
  margin-bottom: 32px;
}

.email__section span,
.pwd__section span {
  font-size: 14px;
  font-weight: 500;
  color: #0a0a0a;
}

.login__title h4,
.kanban__logo h3,
.email__section div span,
.pwd__section div span,
.or__line,
.not__account span {
  color: #0a0a0a;
}

.forgot__pwd {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
}

.forgot__pwd h6 {
  color: #5a5af8;
  font-weight: 600;
}

.login__state {
  color: red;
  font-size: 13px;
  margin-top: 3px;
}

.pass__icons svg {
  fill: #323232;
  height: 17px;
  position: absolute;
  right: 10px;
  top: 17px;
}

@media (max-width: 1024px) {
  .login__section .right__container {
    display: none;
  }
}

@media (max-width: 425px) {
  .login__subsection {
    width: 100%;
  }

  .login__section {
    padding: 25px;
  }

  .login__container {
    width: 100%;
    padding: 10px;
    min-width: unset;
  }
}
