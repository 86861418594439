.sidebar__section {
  width: 300px;
  height: 100vh;
  background: #fff;
  overflow-y: scroll;
  position: relative;
  overflow: scroll;
  z-index: 1;
}

.sidebar__logo {
  object-fit: cover;
  width: 175px;
}

.sidebar__section::-webkit-scrollbar {
  display: none;
}

.deactive__projectlist {
  position: relative;
  transition: all 0.4s ease;
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  cursor: pointer;
  /* margin: 30px auto; */
  display: flex !important;
  justify-content: center !important;
}
.add_updates {
  font-weight: 400px;
  font-size: 16px;
  line-height: 18.15px;
  color: #000;
}
.project__list svg,
.deactive__projectlist svg {
  width: 21px;
  height: 21px;
  border-radius: 25%;
  color: #000;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hover__remove {
  color: white !important;
}

.icon_style {
  height: 2.6rem;
  width: 2.6rem;
}

.sidebar_style {
  background: #0a0a0a;
  width: 233px;
  display: flex;
  justify-content: center;
}

.sidebar__desc {
  display: flex;
  align-items: center;
  justify-content: left;
  /* margin: 30px 0; */
  padding: 10px 10px;
  border-left: 1px solid transparent;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.sidebar__text {
  color: #6f767e;
  font-weight: 500;
  font-size: 16px;
}

.sidebar__text__active {
  color: #292929;
  font-weight: 600;
  font-size: 16px;
}

.sidebar__descTwo {
  display: flex;
  align-items: center;
  justify-content: left;
  /* margin: 30px 0; */
  /* border-left: 1px solid #1ee77b;
    border-top: 1px solid #1ee77b;
    border-bottom: 1px solid #1ee77b;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px; */
  padding: 10px 10px;
  border-radius: 12px;
  background: var(--Neutral-03, #efefef);
}

.second__sidebar__desc {
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 30px 0;
  padding: 10px 10px;
}

.second__sidebar__descTwo {
  border-left: 1px solid #1ee77b;
  border-top: 1px solid #1ee77b;
  border-bottom: 1px solid #1ee77b;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 10px 10px;
  background: #828287;
}

.second_sidebar {
  display: none;
}

.sidebar__menu {
  /* padding-left: 40px; */
}

.close_button {
  background: white;
}

.headerSidebarCombo {
  position: unset;
}

.icon_margin {
  /* margin: 15px 0px;
    padding-right: 40px; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.burger_button {
  color: var(--modal-color);
}

.sidebar_font_style {
  color: #fff;
}

.nav-link:focus {
  color: white !important;
}

.add__Programs {
  width: 52px;
  height: 52px;
  background: #d3d3d3;
  border: 1px solid #d3d3d3;
  border-radius: 12px;
  position: relative;
}

.add__Programs:before {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.add__Programs:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 32%;
  display: block;
  width: 18px;
  height: 2px;
  background: #000;
}

.nav-link {
  color: #fff;
}

.add__Programs:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 32%;
  display: block;
  width: 18px;
  height: 2px;
  background: #000;
}

#my-tooltip {
  background: #000 !important;
  color: #fff !important;
}

.admin_add_program,
.admin_manage_program {
  list-style-type: none;
}

.logout__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.logout__btn img {
  bottom: 20px;
  height: 30px;
  width: 30px;
}

.side__icon__size {
  height: 1.8em;
  width: 1.8em;
}

a {
  text-decoration: none;
  /* color: #fff; */
  font-weight: 600;
}

.icon__style__sidebar {
  height: 1.3em;
  width: 1.3em;
}

.icon__style__sidebarTwo {
  height: 1em;
  width: 1em;
  border-radius: 25%;
}

.nav-link:hover {
  color: #000 !important;
}

.sidebar__margin {
  margin-top: 70px;
}

.dashboard-icon {
  border-radius: 0px;
}

@media only screen and (max-width: 1024px) {
  .sidebar__section {
    display: none;
  }

  .second_sidebar {
    display: block;
    z-index: 10;
  }

  .headerSidebarCombo {
    position: fixed !important;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .padding__side {
    padding-left: 15px;
  }
}
