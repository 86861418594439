.no__transcation {
  max-width: 600px;
  margin: 0 auto;
}
.report__header__border {
  border-bottom: 2px solid #d3d3d3;
}
h6 {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 2px;
}
.employee-form-control {
  margin-bottom: 10px !important;
  border-radius: 8px !important;
}
.FormSelect {
  font-size: 12px;
}

.dropdown-basic-button .add-button .dropdown-toggle {
  height: 40px;
  width: 100%;
  border-radius: 8px;
  padding: 6px;
  background-color: #ffffff !important;
  color: #000;
  display: flex;
  align-items: center;
  gap: 4px;
}
.switch-button {
  align-items: center;
  display: flex;
  justify-content: center;
}
.no__transcation h5 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.employee__section {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.employee__section,
.employee__listing {
  padding: 10px 10px 0 10px;
}

.employee__listing {
  background-color: #fff;
  border-radius: 8px;
  margin-top: 15px;
}

.employee__listing p,
.employee__listing h6,
.employee__listing hr {
  color: #fff;
}

.add__cmp__form .cmp__name,
.add__cmp__form .cmp__email,
.add__cmp__form .cmp__password {
  height: 40px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--select-border);
  padding: 6px;
  background-color: var(--select-background);
  color: var(--text-color);
}

.cmp__premium {
  width: 60px !important;
  height: 25px;
}

.employee__subsection .add__employee {
  border: none;
  border-radius: 6px;
  color: #ffffff;
  padding: 10px 15px;
  font-weight: 500;
  background-color: #f47e53;
}

.remove__employee {
  border: none;
  padding: 7px 10px;
  border-radius: 6px;
}
.emp__close__btn {
  border: 1px solid #000;
  color: #000;
  background-color: #fff;
  cursor: pointer;
  width: 230px;
  height: 47px;
  line-height: 47px;
  border-radius: 5px;
  padding: 0;
  font-weight: 500;
}

.emp__save__btn {
  background-color: #f47e53;
  color: #000;
  cursor: pointer;
  outline: none;
  width: 100%;
  height: 47px;
  line-height: 47px;
  border-radius: 5px;
  padding: 0;
  border: none;
  color: #fff;
  font-weight: 500;
  margin-top: 8px;
}

.emp__save__btn_newEmployee {
  background-color: #855c94;
  color: #000;
  cursor: pointer;
  outline: none;
  width: 230px;
  height: 47px;
  line-height: 47px;
  border-radius: 5px;
  padding: 0;
  border: none;
  color: #fff;
  font-weight: 500;
}

.total__amount {
  color: #37aa1b;
  font-size: 18px;
}

.employee__listing p {
  font-weight: 600;
}

.employee__header {
  background: #fff;
  border-radius: 4px;
}

.employee__data h6 {
  min-width: 200px;
}

.password__section .pwd__eye__icons {
  position: absolute;
  right: 10px;
  top: 8px;
}

.pwd__eye__icons svg {
  height: 17px;
  width: 17px;
}

.add__close__section {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.employee__list__table {
  max-width: 100%;
  vertical-align: middle !important;
}

.employee__list__table td,
.employee__list__table th {
  color: #000;
}

.employee__list__table th {
  font-weight: 500;
}

.employee__list__table tbody tr {
  border-bottom: 1px solid #d3d3d3 !important;
}
.employee__list__table thead tr th {
  border-bottom: 0 !important;
}

.remove__employee__modal {
  border: 1px solid #000;
  background: transparent;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
}
.modal-heading {
  margin-bottom: 4px;
}

.container {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.MuiPaper-root {
  overflow-y: unset !important;
}

.MuiDialogContent-dividers {
  overflow-y: unset !important;
}

@media (max-width: 1024px) {
  .employee__section {
    margin-left: 10px;
  }
}

@media (max-width: 576px) {
  .employee__list__table,
  .employee__header {
    width: 576px;
  }
  .employee__data {
    overflow-x: auto;
  }
}

/* this css use for select dropdown */
.Employ_select .css-1nmdiq5-menu {
  width: max-content !important;
}

.Employ_Technology .select__menu .select__menu-list {
  max-height: 217px !important;
}
.select__placeholder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
