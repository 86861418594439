.category__section {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 100%;
}
.category-modal .modal-content {
  max-width: 500px !important;
  min-width: 300px !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
  overflow-y: unset !important;
}

.add__category__form .category__name {
  height: 40px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--select-border);
  padding: 6px;
  background-color: var(--select-background);
  color: var(--text-color);
}

.category__subsection {
  height: 100%;
  overflow: auto;
}

.category__subsection .add__category {
  border: none;
  color: #fff;
  border-radius: 6px;
  padding: 10px 15px;
  font-weight: 500;
  background-color: #f47e53;
}

.category__save__btn {
  background-color: #f47e53;
  color: #000;
  cursor: pointer;
  outline: none;
  width: 130px !important;
  height: 52px;
  line-height: 47px;
  border-radius: 5px;
  padding: 0;
  border: none;
  color: #fff;
  font-weight: 500;
}
.nav-link-custom {
  background-color: #fff !important;
  border: 2px solid #f47e53 !important;
  color: #f47e53 !important;
  margin-right: 5px;
  padding: 3px 15px !important;
}
.nav-link-custom:hover {
  background-color: #ffebe3 !important;
  color: #f47e53 !important;
}
.nav-link-custom-active {
  background-color: #f47e53 !important;
  color: #fff !important;
}
.nav-link-custom-active:hover {
  background-color: #f47e53 !important;
  color: #fff !important;
}

@media (max-width: 1024px) {
  .category__section {
    margin-left: 10px;
  }
}
