.no__transcation {
  max-width: 600px;
  margin: 0 auto;
}

.no__transcation h5 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.project__section {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.project-modal {
  overflow-y: unset;
}

.project-modal .modal-content {
  max-width: 550px !important;
  min-width: 300px !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
  overflow-y: unset !important;
}
.project__section,
.project__listing {
  padding: 10px;
}
.modal-heading {
  margin-bottom: 8px;
}

.project__listing {
  background-color: #fff;
  border-radius: 8px;
  margin-top: 15px;
}

.project__listing p,
.project__listing h6,
.project__listing hr {
  color: #fff;
}

.add__project__form .project__name,
.add__project__form .project__email,
.add__project__form .project__password {
  height: 40px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--select-border);
  padding: 6px;
  background-color: var(--select-background);
  color: var(--text-color);
}

.project__premium {
  width: 60px !important;
  height: 25px;
}
.project__name:disabled {
  background-color: #e8e8e8;
  cursor: not-allowed;
}

.project__subsection .Filter_Report {
  border: none;
  color: #6f767e;
  border-radius: 6px;
  padding: 10px 15px;
  font-weight: 500;
  background-color: #fff;
  border: 1px solid #6f767e;
}

.remove__project {
  border: none;
  padding: 7px 10px;
  border-radius: 6px;
}
.project__close__btn {
  border: 1px solid #000;
  color: #000;
  background-color: #fff;
  cursor: pointer;
  width: 230px;
  height: 47px;
  line-height: 47px;
  border-radius: 5px;
  padding: 0;
  font-weight: 500;
}
.report__header__border {
  border-bottom: 1px solid #d3d3d3 !important;
}
.project__save__btn {
  background-color: #f47e53;
  color: #000;
  cursor: pointer;
  outline: none;
  width: 100%;
  height: 50px;
  line-height: 47px;
  border-radius: 12px;
  padding: 0;
  border: none;
  color: #fff;
  font-weight: 500;
}

.project__save__btn_newClient {
  background-color: #f47e53;
  color: #000;
  cursor: pointer;
  outline: none;
  width: 230px;
  height: 47px;
  line-height: 47px;
  border-radius: 5px;
  padding: 0;
  border: none;
  color: #fff;
  font-weight: 500;
}

.total__amount {
  color: #37aa1b;
  font-size: 18px;
}

.project__listing p {
  font-weight: 600;
}

.project__header {
  background: #fff;
  border-radius: 4px;
}

.project__data h6 {
  min-width: 200px;
}

.password__section .pwd__eye__icons {
  position: absolute;
  right: 10px;
  top: 8px;
}

.pwd__eye__icons svg {
  height: 17px;
  width: 17px;
}

.add__close__section {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.project__list__table {
  max-width: 100%;
}

.project__list__table td,
.project__list__table th {
  color: #000;
}

.project__list__table th {
  font-weight: 500;
  font-size: 16px !important ;
  line-height: 24px !important;
}

.project__list__table tbody tr {
  border-bottom: 1px solid #d3d3d3 !important;
}
.project__list__table thead tr th {
  border-bottom: 0 !important;
}

.add-new-client {
  color: #ff5722;
  cursor: pointer;
  margin-top: 5px;
  display: inline-block;
  font-size: 14px;
}
.Add_New_Client {
  font-size: 16px;
}

.add-new-client:hover {
  text-decoration: underline;
}

.client-name-container {
  padding: 10px;
}

.client-name-input .select__control {
  border-radius: 5px;
  border: 1px solid #ced4da;
  padding: 5px 10px;
}

.remove__project__modal {
  border: 1px solid #000;
  background: transparent;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
}

@media (max-width: 1024px) {
  .project__section {
    margin-left: 10px;
  }
}

@media (max-width: 804px) {
  .project__list__table,
  .project__header {
    width: 804px;
  }
  .project__data_table {
    overflow-x: auto;
  }
}

.add__employee {
  border: none;
  border-radius: 6px;
  color: #ffffff;
  padding: 10px 15px;
  font-weight: 500;
  background-color: #f47e53;
}

.project_search{
  height: 38px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--select-border);
  padding: 6px;
  background-color: var(--select-background);
  color: var(--text-color);
}