.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.date-picker input {
  padding: 0.5rem;
  width: 120px;
}

.date-picker svg {
  font-size: 1.2rem;
}

.ql-stroke {
  stroke: #999 !important; /* Ensure default stroke color is correct */
}

.ql-toolbar .ql-formats button:hover .ql-stroke,
.ql-toolbar .ql-formats button.ql-active .ql-stroke {
  stroke: #007bff !important; /* Blue color on hover or when active */
}

.ql-toolbar .ql-formats button:hover .ql-fill,
.ql-toolbar .ql-formats button.ql-active .ql-fill {
  fill: #007bff !important; /* Fill for icons that use the 'fill' property */
}

/* Ensure custom styles for React Quill editor */
.custom-quill .ql-container.ql-snow {
  border: none;
}

.custom-quill .ql-toolbar.ql-snow {
  border: none;
}

.custom-quill .ql-editor.ql-blank::before {
  color: #999;
}

.custom-quill .ql-editor {
  border: none !important;
  padding: 5px 10px;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Add these styles to your global CSS file */
.comment-section ul {
  margin: 0; /* Removes default margin */
  padding-left: 1.5rem; /* Adds padding to the left */
  list-style-type: disc; /* Adds bullet points */
}

.mention {
  color: blue; /* Change text color */
  background-color: lightgray; /* Background color */
  padding: 2px 4px; /* Add some padding */
  border-radius: 4px; /* Round the corners */
}

.editor {
  box-sizing: border-box;
  cursor: text;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}

.myz2dw1 {
  display: flex;
  align-items: center;
  padding: 7px 10px;
}

.mtiwdxc {
  display: flex;
  align-items: center;
  padding: 7px 10px;
}
