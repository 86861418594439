.no__transcation {
  max-width: 600px;
  margin: 0 auto;
}

.no__transcation h5 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.client__section {
  /* border-color: #919191;
    border-style: solid;
    border-width: 0 1px 1px 1px; */
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.client-modal .modal-content {
  max-width: 500px !important;
  min-width: 300px !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
  overflow-y: unset !important;
}

.client__section,
.client__listing {
  padding: 10px;
}
.modal-heading {
  /* margin-bottom: 8px; */
  font-size: 16px;
}

.client__listing {
  background-color: #fff;
  border-radius: 8px;
  margin-top: 15px;
}

.client__listing p,
.client__listing h6,
.client__listing hr {
  color: #fff;
}

.add__client__form .client__name,
.add__client__form .client__email,
.add__client__form .client__password {
  height: 40px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--select-border);
  padding: 6px;
  background-color: var(--select-background);
  color: var(--text-color);
}

.client__premium {
  width: 60px !important;
  height: 25px;
}

.client__subsection .add__client {
  border: none;
  color: #fff;
  border-radius: 6px;
  padding: 10px 15px;
  font-weight: 500;
  background-color: #f47e53;
}

.remove__client {
  border: none;
  padding: 7px 10px;
  border-radius: 6px;
}
.client__close__btn {
  border: 1px solid #000;
  color: #000;
  background-color: #fff;
  cursor: pointer;
  width: 230px;
  height: 47px;
  line-height: 47px;
  border-radius: 5px;
  padding: 0;
  font-weight: 500;
}

.client__save__btn {
  border: none;
  color: #fff;
  border-radius: 6px;
  padding: 10px 15px;
  font-weight: 500;
  background-color: #f47e53;
}

.client__save__btn_newClient {
  background-color: #f47e53;
  color: #000;
  cursor: pointer;
  outline: none;
  width: 230px;
  height: 47px;
  line-height: 47px;
  border-radius: 5px;
  padding: 0;
  border: none;
  color: #fff;
  font-weight: 500;
}

.total__amount {
  color: #37aa1b;
  font-size: 18px;
}

.client__listing p {
  font-weight: 600;
}

.client__header {
  background: #fff;
  border-radius: 4px;
}

.client__data h6 {
  min-width: 200px;
}

.password__section .pwd__eye__icons {
  position: absolute;
  right: 10px;
  top: 8px;
}

.pwd__eye__icons svg {
  height: 17px;
  width: 17px;
}

.add__close__section {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.client__list__table {
  max-width: 100%;
}

.client__list__table td,
.client__list__table th {
  color: #000;
}

.client__list__table th {
  font-weight: 500;
  font-size: 16px !important;
  line-height: 24px !important;
}

.client__list__table tbody tr {
  border-bottom: 1px solid #d3d3d3 !important;
}

.client__list__table thead tr th {
  border-bottom: 0 !important;
}

.remove__client__modal {
  border: 1px solid #000;
  background: transparent;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
}

@media (max-width: 1024px) {
  .client__section {
    margin-left: 10px;
  }
}

@media (max-width: 576px) {
  .client__list__table,
  .client__header {
    width: 576px;
  }
  .client__data {
    overflow-x: auto;
  }
}

/* .Employ_Country .css-1n6sfyn-MenuList {
  max-height: 200px !important;
} */
