.not__found__img {
  height: auto;
  width: 30%;
}

.not__found__text {
  height: auto;
  width: 14%;
}

.not__found__main {
  height: calc(100vh - 300px);
  display: flex;
  align-items: center;
  background: #fff;
}
