.modal-heading {
  margin-bottom: 8px;
}

.add__update__form .update__name,
.add__update__form .add__update__form {
  /* height: 40px !important; */
  border-radius: 8px !important;
  border: 1px solid var(--select-border);
  padding: 6px;
  background-color: var(--select-background);
  color: var(--text-color);
  width: 100%;
}

.update__save__btn {
  background-color: #f47e53;
  color: #000;
  cursor: pointer;
  outline: none;
  width: 130px !important;
  height: 52px;
  line-height: 47px;
  border-radius: 5px;
  padding: 0;
  border: none;
  color: #fff;
  font-weight: 500;
}
.Add_Task {
  font-size: 16px;
}
.task-input {
  height: 40px !important;
  border-radius: 8px !important;
  border: 1px solid var(--select-border);
  padding: 6px;
  background-color: var(--select-background);
  color: var(--text-color);
  width: 100%;
}

.add_update .select__menu-list {
  max-height: 200px !important;
}
