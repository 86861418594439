.setting__section {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.setting__subsection {
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
}

.account__details input {
  width: 100%;
  /* height: 40px; */
  border-radius: 12px;
  border: 0.5px solid #fff;
  padding: 12px;
  background: #f5f5f5;
  color: #000;
}

.admin__profile__pwd__section input {
  width: 100%;
  height: 42px;
  padding: 10px 12px;
  border-radius: 6px;
  box-sizing: border-box;
  border: 0.5px solid #fff;
  font-size: 14px;
  background: #f5f5f5;
  color: #000;
}

.admin__profile__pwd__section input:focus {
  outline: none;
}

.admin__profile__pwd__section {
  margin-top: 35px;
}

.admin__profile__pwd__section span {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
  margin-top: -4px;
}

.setting__section .upload__button {
  padding: 10px;
  font-weight: 500;
  border-radius: 25px;
  border: none;
  color: #fff;
  background-color: #f37d53;
  height: auto;
  margin-top: 10xp;
  font-size: 15px;
  margin-top: 15px;
}

.profile__img img {
  border-radius: 100%;
  height: 140px;
  width: 140px;
}
.profile__img label {
  cursor: pointer;
}
.profile__img,
.upload__button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin__password__setting h6,
.account__details h6,
.admin__password__setting span,
.setting__subsection h5,
hr {
  color: #000;
}

.save__section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.admin__save__change__btn {
  background-color: #f37d53;
  color: #fff;
  width: 150px;
  height: 40px;
  border-radius: 25px;
  margin-top: 16px;
  border: none;
}

.admin__profile__pwd__section .eye__icons {
  position: absolute;
  right: 10px;
  top: 13px;
}

.admin__profile__pwd__section .eye__icons svg {
  height: 17px;
  fill: #9e9e9f;
}

@media (max-width: 1024px) {
  .setting__section {
    margin-left: 10px;
  }
}

@media (max-width: 425px) {
  .admin__password__setting {
    margin-top: 20px;
  }
}

.setting__subsection {
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
}
.setting__subsection ul {
  /* width: fit-content; */
}

.nav-pills .nav-link.active {
  background: transparent;
  color: #f47e53 !important;
  border-bottom: 2px solid #f47e53;
  font-weight: 600;
  border-radius: 0% !important;
}

.nav-link {
  color: #000;
  font-weight: 600;
}

.profile__img {
  border-radius: 50%;
  margin-bottom: 20px;
  object-fit: cover;
}

.upload__button {
  display: block;
  width: 50%;
  text-align: center;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.account__details {
  margin-top: 20px;
}

.form-control {
  margin-bottom: 20px;
  /* padding: 10px; */
  border: 1px solid #ddd;
  border-radius: 4px;
  padding-right: 20px;
}

.password-toggle {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.edit__program__btn {
  min-width: 160px;
  border: none;
  padding: 10px;
  border-radius: 6px;
  font-weight: 500;
  background: #f47e53;
  color: #fafafa;
}

.edit__program__btn:hover {
  color: white !important;
}

.password-toggle {
  position: absolute;
  right: 10px;
  top: 66%;
  transform: translateY(-50%);
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.form-group svg {
  display: block;
  width: 20px;
  vertical-align: middle;
  height: 20px;
}
